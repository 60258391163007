const research = {
  title: 'Research',
  subTitle: 'Sugar is nonessential part of our diet',
  pdf: {
    title: 'Sketchbook',
    subTitle: 'Graphic Design Practices',
    paragraph1: `
      This project has been started with Data collection, by creating some simple graphs and data visualisation. The idea of the Sugar project has come later, and sugar packaging as a final product has designed.
`,
    paragraph2: `
      Let's have a look through PDF Sketchbook.
`,
  },
  paragraph1: `
    Too much added sugars to foods increasing the health risk.
`,
  paragraph2: `
    According to Sugar reduction: Report on progress between 2015 and 2018. Over the years 2015 – 2018 there has been an increase of sugar sold from 722.976 tonnes to 741,700 tonnes, which represents an increase of 2.6%.
`,
  paragraph3: `
  The population of Great Britain increased during this period in sugar by 0.5% purchased per person. The largest increases in tonnes of sugar sold were 16.3% for ice cream, lollies and sorbets, 10.4% for chocolate confectionery, 6.4% for sweet spreads and sauces, 4.9% for sweet confectionery and 3.1% for biscuits. The largest decreases were 10.1% for puddings, 8.7% for yogurts and fromage frais and 7.5% for breakfast cereals.
`,
  reference: "https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/839756/Sugar_reduction_yr2_progress_report.pdf",
  group1: {
    title: 'Sweeteners',
    subTitle: 'Sugar substitutes',
    paragraph1: `
  When choosing a low-calorie sweetener, some general considerations include:
  `,
    reference: "https://www.medicalnewstoday.com/articles/323469#summary",
  },
  group2: {
    title: 'Fatty liver',
    subTitle: 'Consumption of too much sugar can lead to both obesity and fatty liver disease',
    paragraph1: `
    Fatty liver disease is becoming increasingly common in many parts of the world, affecting about 25% of people globally.
  `,
    paragraph1Reference: "https://www.ncbi.nlm.nih.gov/pubmed/26707365",
    paragraph2: `
    It is linked to obesity, type 2 diabetes and other disorders characterized by insulin resistance.
  `,
    paragraph3: `
    What’s more, if fatty liver isn’t addressed, it may progress to more serious liver disease and other health problems
  `,
    paragraph3Reference: "https://www.healthline.com/nutrition/fatty-liver",
    paragraph4: `
    There is clear evidence that high sugar consumption leads to obesity and fatty liver disease. Synthetic and natural alternatives to sugar are available, but little is known about the effects of these non-caloric sweeteners on the liver. A new study led by Rohit Kohli, MBBS, MS, shows that stevia extract can reduce markers of fatty liver disease. The results of the pre-clinical research, published in the journal Scientific Reports led to a clinical trial, now in progress.
  `,
    paragraph5: `
    Using a preclinical model, he tested two non-caloric sweeteners, sucralose and stevia extract. Both are widely available and appear in many sweetened foods and drinks. "We were interested in those two compounds because they are the newest and least studied in the context of liver disease and obesity," says Dr. Kohli.
  `,
    paragraph6: `
    The results were striking. "We compared these sweeteners head to head with sugar," he says. "Stevia extract lowers glucose levels and improves markers of fatty liver disease." These markers include fibrosis and fat levels in the liver. The study also uncovered some potential mechanisms that could be responsible for reversing these markers of fatty liver disease. "We saw a decrease in signs of cellular stress and some changes in the gut microbiome," says Dr. Kohli, "but there is more work to do in order for us to understand the clinical relevance".
  `,
    paragraph6Reference: "https://www.nature.com/articles/s41598-020-63688-z#Abs1",
    paragraph7: {
      header: `
        More information
    `,
    body: `
      Xi, D., Bhattacharjee, J., Salazar-Gonzalez, R. et al. Rebaudioside affords hepatoprotection ameliorating sugar sweetened beverage- induced nonalcoholic steatohepatitis. Sci Rep 10, 6689 (2020).
    `,
    reference: 'https://doi.org/10.1038/s41598-020-63688-z',
    },
  },
  group3: {
    title: 'Addiction',
    subTitle: 'Sugar has drug-like effects',
    paragraph1: `
      On the right is your brain on sugar. On the left side is a normal brain. Central is brain on cocaine. Notice the similarities?
  `,
    paragraph2: `
      Notice that the normal brain has a lot more red stuff highlighted in it — called Dopamine. This chemical is produced in the part of the brain that is associated with reward. When someone experiences a reward — say while eating a really good meal — their Dopamine (red stuff) level spikes. For addicts, the opposite is true: That spike in Dopamine only comes in anticipation of the reward, as opposed to the actual reward itself. Later, once the reward is gotten, the effects are blunted because the brain has been flooded with dopamine as it thought about eating.
    `,
    paragraph3: `
    This is what sugar does to your brain — the exact same thing smoking, alcohol and cocaine do
    `,
    paragraph4: `
    Sugar has drug-like effects in the reward centre of the brain and like drugs, large amounts of sugar can be very bad for you.
    `,
    reference: "https://www.mic.com/articles/88015/what-happens-to-your-brain-on-sugar-explained-by-science",
  },
  group4: {
    title: 'References',
    subTitle: 'Related articles',
    link1: {
      text: `
        Is Sugar Worse Than Tobacco? Get the shocking truth about sugar’s effects on the body
      `,
      reference: "https://www.shape.com/healthy-eating/sugar-worse-tobacco",
    },
    link2: {
      text: `
        Cut sugar intake to 5 teaspoons a day, scientists urge
      `,
      reference: "https://www.telegraph.co.uk/news/science/science-news/10507091/Cut-sugar-intake-to-5-teaspoons-a-day-scientists-urge.html",
    },
    link3: {
      text: `
        Why is Britain fatter than ever?
      `,
      reference: "https://www.telegraph.co.uk/lifestyle/wellbeing/diet/10525107/Why-is-Britain-fatter-than-ever.html",
    },
    link4: {
      text: `
        Sweet poison: why sugar is ruining our health
      `,
      reference: "https://www.telegraph.co.uk/foodanddrink/healthyeating/9987825/Sweet-poison-why-sugar-is-ruining-our-health.html",
    },
    link5: {
      text: `
        How to kick the sugar habit: tips and low-sugar recipes
      `,
      reference: "https://www.telegraph.co.uk/foodanddrink/healthyeating/9987977/How-to-kick-the-sugar-habit-tips-and-low-sugar-recipes.html",
    },
    link6: {
      text: `
        Three fizzy drinks per day could triple chance of heart disease
      `,
      reference: "https://www.telegraph.co.uk/news/health/news/10615664/Three-fizzy-drinks-per-day-could-triple-chance-of-heart-disease.html",
    },
    link7: {
      text: `
        The tobacco farmers chasing a sweeter crop
      `,
      reference: "https://www.bbc.co.uk/news/business-50629107?intlink_from_url=https://www.bbc.co.uk/news/topics/cly4p1dlvkzt/sugar&link_location=live-reporting-story",
    },
    link8: {
      text: `
        Yoghurts (even organic ones) 'full of sugar'
      `,
      reference: "https://www.bbc.co.uk/news/health-45565364?intlink_from_url=https://www.bbc.co.uk/news/topics/cly4p1dlvkzt/sugar&link_location=live-reporting-story",
    },
  },
  expansionPanel1: {
    title: 'Stevia',
    body:
      "Stevia is a natural sweetener that comes from the Stevia rebaudiana plant. To make stevia, manufacturers extract chemical compounds called steviol glycosides from the leaves of the plant. Stevia has several pros and cons - this sweetener is calorie-free and does not raise blood sugar levels. However, it is often more expensive than other sugar substitutes on the market. Stevia also has a bitter aftertaste that many people may find unpleasant. For this reason, some manufacturers add other sugars and ingredients to balance the taste. This can reduce the nutritional benefit of pure stevia.",
  },
  expansionPanel2: {
    title: 'Tagatose',
    body:
      "Tagatose is a form of fructose that is around 90 percent sweeter than sucrose. Although it is rare, some fruits, such as apples, oranges, and pineapples, naturally provide tagatose. Manufacturers use tagatose in foods as a low-calorie sweetener, texturizer, and stabilizer. This sugar substitute is more expensive than other low-calorie sweeteners and may be harder to find in stores.",
  },
  expansionPanel3: {
    title: 'Sucralose',
    body:
      "People can use sucralose instead of sugar when baking. This sweetener is about 600 times sweeter than table sugar but contains very few calories. Sucralose is one of the most popular artificial sweeteners, and it is widely available. Manufacturers add it to a range of products from chewing gum to baked goods.",
  },
  expansionPanel4: {
    title: 'Aspartame',
    body:
      "Aspartame is a very common artificial sweetener that has been available since the 1980s. It is around 200 times sweeter than sugar, and manufacturers add it to a wide variety of food products, including diet soda. Unlike sucralose, aspartame is not a good sugar substitute for baking. Aspartame breaks down at high temperatures, so people generally only use it as a tabletop sweetener. Aspartame is also not safe for people with a rare genetic disorder known as phenylketonuria.",
  },
  expansionPanel5: {
    title: 'Acesulfame potassium',
    body:
      "Acesulfame potassium, also known as acesulfame K and Ace-K, is an artificial sweetener that is around 200 times sweeter than sugar. Manufacturers often combine acesulfame potassium with other sweeteners to combat its bitter aftertaste.",
  },
  expansionPanel6: {
    title: 'Saccharin',
    body:
      "Cafes and restaurants may provide saccharin sweeteners. Saccharin is another widely available artificial sweetener.",
  },
  expansionPanel7: {
    title: 'Neotame',
    body:
      "Neotame is a low-calorie artificial sweetener that is about 7.000-13.000 times sweeter than table sugar. This sweetener can tolerate high temperatures, making it suitable for baking, flavor enhancer for all foods except for meat and poultry. It is available under the brand name Newtame.",
  },
};

export default research;
