const data = [
  {
    id: 'Healthy style',
    data: [
      {
        x: 10,
        y: 2,
      },
      {
        x: 11,
        y: 8,
      },
      {
        x: 12,
        y: 4,
      },
      {
        x: 13,
        y: 5,
      },
      {
        x: 14,
        y: 7,
      },
      {
        x: 15,
        y: 17,
      },
      {
        x: 16,
        y: 17,
      },
      {
        x: 17,
        y: 7,
      },
      {
        x: 18,
        y: 14,
      },
    ],
  },
  {
    id: 'Bad habits',
    data: [
      {
        x: 10,
        y: 12,
      },
      {
        x: 11,
        y: 20,
      },
      {
        x: 12,
        y: 13,
      },
      {
        x: 13,
        y: 36,
      },
      {
        x: 14,
        y: 9,
      },
      {
        x: 15,
        y: 8,
      },
      {
        x: 16,
        y: 30,
      },
      {
        x: 17,
        y: 20,
      },
      {
        x: 18,
        y: 21,
      },
    ],
  },
  {
    id: 'Soft style',
    data: [
      {
        x: 10,
        y: 26,
      },
      {
        x: 11,
        y: 15,
      },
      {
        x: 12,
        y: 17,
      },
      {
        x: 13,
        y: 15,
      },
      {
        x: 14,
        y: 17,
      },
      {
        x: 15,
        y: 13,
      },
      {
        x: 16,
        y: 6,
      },
      {
        x: 17,
        y: 22,
      },
      {
        x: 18,
        y: 15,
      },
    ],
  },
  {
    id: 'Medium style',
    data: [
      {
        x: 10,
        y: 19,
      },
      {
        x: 11,
        y: 18,
      },
      {
        x: 12,
        y: 9,
      },
      {
        x: 13,
        y: 27,
      },
      {
        x: 14,
        y: 13,
      },
      {
        x: 15,
        y: 11,
      },
      {
        x: 16,
        y: 12,
      },
      {
        x: 17,
        y: 22,
      },
      {
        x: 18,
        y: 21,
      },
    ],
  },
  {
    id: 'Avoiding sugar',
    data: [
      {
        x: 10,
        y: 7,
      },
      {
        x: 11,
        y: 4,
      },
      {
        x: 12,
        y: 1,
      },
      {
        x: 13,
        y: 2,
      },
      {
        x: 14,
        y: 4,
      },
      {
        x: 15,
        y: 1,
      },
      {
        x: 16,
        y: 3,
      },
      {
        x: 17,
        y: 4,
      },
      {
        x: 18,
        y: 13,
      },
    ],
  },
];

export default data;

