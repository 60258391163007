import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  Fade,
  Typography,
  IconButton,
  Button,
  Grid,
  Container,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.custom.yellow,
    color: theme.palette.custom.black,
    transform: 'none',
    boxShadow: 'none',
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
    },
  },
  project: {
    flexGrow: 1,
    fontWeight: 900,
  },
  menuButton: {
    marginRight: '16px',
    float: 'left',
  },
  homepageLinkContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '11px',
    },
  },
  homepageLink: {
    color: theme.palette.custom.black,
    textDecoration: 'none',
    fontWeight: 900,
    marginLeft: '-8px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  },
  links: {
    transform: 'none',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '12px',
      paddingBottom: '12px',
      textAlign: 'left',
      borderTop: `8px solid ${theme.palette.custom.black}`,
    },
  },
  buttonLink: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingTop: '0',
      paddingLeft: '0',
      marginRight: '8px',
    },
  },
  link: {
    color: theme.palette.custom.black,
    textDecoration: 'none',
    fontWeight: 900,
    fontSize: theme.typography.pxToRem('13'),
  },
}));

const TopNavBar = props => {
  const classes = useStyles();
  const [toggleLinks, setToggleLinks] = useState(null);
  const [toggled, setToggled] = useState(null);

  useEffect(() => {
    if (isWidthDown('sm', props.width)) {
      if (!toggled) {
        setToggleLinks(false);
      }
    } else {
      setToggled(false);
      setToggleLinks(true);
    }
  }, [toggleLinks, setToggleLinks, toggled, setToggled, props.width]);

  const handleClick = () => {
    setToggled(true);
    setToggleLinks(!toggleLinks);
  };

  return (
    <Fade in={true} timeout={5000}>
      <Container
        maxWidth={false}
        disableGutters
        {...props}
        className={classes.container}
      >
        <AppBar
          position={isWidthDown('sm', props.width) ? 'static' : 'fixed'}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item xs={12} md={4} className={classes.project}>
                <Hidden mdUp>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={handleClick}
                  >
                    <Menu />
                  </IconButton>
                </Hidden>
                <Typography
                  variant="h5"
                  className={classes.homepageLinkContainer}
                >
                  <NavLink to="/" className={classes.homepageLink}>
                    Sugar Project
                  </NavLink>
                </Typography>
              </Grid>
              {toggleLinks && (
                <Grid item xs={12} md={8} className={classes.links}>
                  <Button className={classes.buttonLink}>
                    <NavLink to="/research" className={classes.link}>
                      Research
                    </NavLink>
                  </Button>
                  <Button className={classes.buttonLink}>
                    <NavLink to="/data-visualization" className={classes.link}>
                      Data visualization
                    </NavLink>
                  </Button>
                  <Button className={classes.buttonLink}>
                    <NavLink to="/production" className={classes.link}>
                      Production
                    </NavLink>
                  </Button>
                  <Button className={classes.buttonLink}>
                    <NavLink to="/opinion" className={classes.link}>
                      Opinion
                    </NavLink>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      </Container>
    </Fade>
  );
};

export default withWidth({ noSSR: true })(TopNavBar);
