import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';

import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';
import ScrollTop from '../components/ScrollTop';

const useStyles = makeStyles(theme => ({
  // Define global styles
  '@global': {
    html: {
      fontSize: '20px',
    },
    body: {
      fontSize: '100%',
      lineHeight: 1.7,
    },
    '.MuiButton-label > .active': {
      color: theme.palette.custom.white,
      backgroundColor: theme.palette.custom.black,
      paddingRight: '8px',
      paddingLeft: '8px',
    },
  },
}));

const Default = props => {
  /* eslint-disable no-unused-vars */
  const classes = useStyles();

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <TopNavBar id="top-navbar" />
          {props.children}
          <Footer />
          <ScrollTop {...props} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Default;
