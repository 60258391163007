import React from 'react';

import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    ...theme.header,
  },
  subHeader: {
    ...theme.subHeader,
  },
  divider: {
    ...theme.divider,
  },
  headerIcon: {
    ...theme.headerIcon,
    fontSize: theme.typography.pxToRem(24),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  headerIconSmall: {
    ...theme.headerIconSmall,
    fontSize: theme.typography.pxToRem(24),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(21),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(19),
    },
  },
}));

const Header = props => {
  const classes = useStyles();
  const { title, subTitle, small, noIcon } = props;

  return (
    <>
      <Typography variant={small ? 'h4' : 'h3'} className={classes.header}>
        {!noIcon &&
        <Typography
          component="span"
          className={small ? classes.headerIconSmall : classes.headerIcon}
        >
          |||
        </Typography>
        }
        {title}
      </Typography>
      <Typography variant={small ? 'h6' : 'h5'} className={classes.subHeader}>
        {subTitle}
      </Typography>
      <Divider className={classes.divider} />
    </>
  );
};

export default Header;
