const data = {
  paragraph1: `
    This project has been created to inform and warn people of sugar consumption. Sometimes it seems that one bar of chocolate doesn’t hurt, but in a long time perspective, population become addicted to sweet taste.
  `,
  paragraph2: {
    title: 'Sugar is added everywhere',
    body: 'Except natural sugars that exist in fruits and vegetables.',
  },
  paragraph3: `
    An immense amount of hidden sugars, many people are unaware about, are in flavoured waters, ketchup, soft drinks, energy drinks and fruit juices. But also, in any processed food as a soup, sausages and ready meals, even where the label shows as a healthy choice. Sugar is added in bread, yoghurts, peanut butter, wine, yoghurts, healthy cereals and baked beans.
  `,
  paragraph4: {
    title: 'According to Lisa Renn, an accredited practising dietitian, sugar has a range of purposes in food manufacturing',
    body: `
      It's not only used as a sweetener, but it's also used as a colouring for food consistency and as something to hold the ingredients together.
    `,
  },
  paragraph5: `
    In the past 20 years, sugar consumption worldwide has tripled, food now contains so much added sugars that our body system unavailable to process.
  `,
  paragraph6: `
    At a basic level, sucrose, or table sugar (which is made up of equal molecules of the monosaccharides fructose and glucose) is not metabolised in the same way that a carbohydrate, and if it's not burnt immediately, usually gets stored as fat. And this fat will go to your belly. Excess fructose cannot be converted into energy by the mitochondria inside our cells (which perform this function). Instead, they turn excess fructose into liver fat. That starts a cascade of insulin resistance (insulin promotes sugar uptake from blood) which leads to chronic metabolic disease, including diabetes and heart disease.
   `,
  paragraph7: `
    Look online, and you’ll see fructose described as “fruit sugar” – it’s the nutrient that nature put into apples and pears to entice humans (and birds) to eat them. So do we stop eating fruit to go sugar-free? It’s not that easy. The fruit is sweetened by fructose, but it doesn’t contain very much, although you still shouldn’t eat very sweet fruit like grapes and melon to excess.
  `,
  paragraph8: `
    The problem lies in sources of sweetness like corn syrup, agave or maple syrup and honey, which contain a higher percentage of fructose than fruit, mainly if they have been processed, meaning additional fructose is added in. Some agave nectars, for example, can be 92 per cent fructose, eight per cent glucose.
  `,
  reference: 'https://www.telegraph.co.uk/foodanddrink/healthyeating/9987825/Sweet-poison-why-sugar-is-ruining-our-health.html'
}

export default data;
