import React from 'react';

import {
  Typography,
  Container,
  Grid,
  Collapse,
  Divider,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as UILink } from '@material-ui/icons';

import LazyHero from 'react-lazy-hero';

import Alert from '../components/Alert';
import HeroImage from '../assets/images/hero-1.jpg';

import data from './data/home';

const useStyles = makeStyles(theme => ({
  container: {
    ...theme.container,
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
    marginBottom: '48px',
  },
  grid: {
    ...theme.grid,
  },
  divider: {
    ...theme.divider,
    marginTop: '1.3rem',
  },
  hero: {
    marginBottom: '48px',
  },
  author: {
    backgroundColor: '#000',
    color: '#fff',
    opacity: 0.6,
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
  },
  mediumParagraph: {
    fontSize: theme.typography.pxToRem(21),
    textAlign: 'center',
    marginBottom: '56px',
  },
  bigParagraph: {
    fontSize: theme.typography.pxToRem(28),
    textAlign: 'center',
    marginBottom: '56px',
  },
  greyBox: {
    ...theme.greyBox,
    marginBottom: '56px',
  },
  greyBoxContent: {
    ...theme.greyBoxContent,
  },
  whiteBox: {
    ...theme.whiteBox,
    marginTop: '66px',
    marginBottom: '56px',
  },
  whiteBoxContent: {
    ...theme.whiteBoxContent,
  },
  yellowBox: {
    ...theme.yellowBox,
    marginTop: '66px',
    marginBottom: '56px',
  },
  yellowBoxContent: {
    ...theme.yellowBoxContent,
  },
  linkBox: {
    ...theme.linkBox,
  },
  linkItem: {
    ...theme.linkItem,
  },
  linkIcon: {
    ...theme.linkIcon,
  },
}));

const Home = props => {
  const classes = useStyles();

  return (
    <Collapse in={true} timeout={6000}>
      <Container
        maxWidth={false}
        disableGutters
        className={classes.container}
        id="container"
        {...props}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Alert />
            <LazyHero
              imageSrc={HeroImage}
              parallaxOffset={100}
              opacity={0}
              className={classes.hero}
            >
              <Typography
                variant="h2"
                component="h3"
                display="inline"
                className={classes.author}
              >
                By Inna Halasyova
              </Typography>
            </LazyHero>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <Typography
            variant="body1"
            component="p"
            className={classes.bigParagraph}
          >
            {data.paragraph1}
          </Typography>

          <Grid item xs={12} className={classes.greyBox}>
            <Typography variant="h6" className={classes.greyBoxContent}>
              {data.paragraph2.title}
            </Typography>
            <Typography variant="body1" className={classes.greyBoxContent}>
              {data.paragraph2.body}
            </Typography>
          </Grid>

          <Typography variant="body1" component="p">
            {data.paragraph3}
          </Typography>

          <Grid item xs={12} className={classes.whiteBox}>
            <Typography variant="h6" className={classes.whiteBoxContent}>
              {data.paragraph4.title}
            </Typography>
            <Typography variant="body1" className={classes.whiteBoxContent}>
              <em>{data.paragraph4.body}</em>
            </Typography>
          </Grid>

          <Typography
            variant="body1"
            component="p"
            className={classes.mediumParagraph}
          >
            {data.paragraph5}
          </Typography>
          <Typography variant="body1" component="p">
            {data.paragraph6}
          </Typography>

          <Grid item xs={12} className={classes.yellowBox}>
            <Typography variant="h6" className={classes.yellowBoxContent}>
              {data.paragraph7}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body1" className={classes.yellowBoxContent}>
              {data.paragraph8}
            </Typography>
          </Grid>

          <Typography
            variant="body1"
            component="div"
            className={classes.linkBox}
          >
            <Link
              href={data.reference}
              target="_blank"
              rel="noopener"
              className={classes.linkItem}
            >
              <UILink className={classes.linkIcon} />
              Reference
            </Link>
          </Typography>
        </Grid>
      </Container>
    </Collapse>
  );
};

export default Home;
