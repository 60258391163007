import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Grid,
  Divider,
  Button,
  Tooltip,
} from '@material-ui/core';
import {
  LibraryBooks,
  AssessmentSharp,
  Chat,
  Image,
} from '@material-ui/icons';

import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  subfooter: {
    backgroundColor: theme.palette.custom.black,
    color: theme.palette.custom.yellow,
    marginTop: '16px',
    paddingBottom: '12px',
  },
  subfooterDivider: {
    backgroundColor: theme.palette.custom.grey,
  },
  subfooterLinks: {
    paddingTop: '8px',
    textAlign: 'center',
  },
  homepageLink: {
    color: theme.palette.custom.yellow,
    textDecoration: 'none',
    fontWeight: 900,
  },
  footer: {
    backgroundColor: theme.palette.custom.grey,
    color: theme.palette.custom.white,
    paddingBottom: '16px',
  },
  project: {
    padding: '16px',
  },
  copyright: {
    padding: '14px 0 0 16px',
    color: theme.palette.custom.white,
    fontSize: theme.typography.pxToRem('12'),
    marginBottom: '0',
  },
  link: {
    color: theme.palette.custom.cream,
    '&:hover': {
      color: theme.palette.custom.yellow,
    }
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Container maxWidth={false} disableGutters className={classes.subfooter}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.project}>
              <NavLink to="/" className={classes.homepageLink}>
                Sugar Project
              </NavLink>
            </Typography>
            <Divider className={classes.subfooterDivider} />
            <div className={classes.subfooterLinks}>
              <Tooltip title="Research">
                <Button>
                  <NavLink to="/research" className={classes.link}>
                    <LibraryBooks />
                  </NavLink>
                </Button>
              </Tooltip>
              <Tooltip title="Data visualization">
                <Button>
                  <NavLink to="/data-visualization" className={classes.link}>
                    <AssessmentSharp />
                  </NavLink>
                </Button>
              </Tooltip>
              <Tooltip title="Production">
                <Button>
                  <NavLink to="/production" className={classes.link}>
                    <Image />
                  </NavLink>
                </Button>
              </Tooltip>
              <Tooltip title="Opinion">
                <Button>
                  <NavLink to="/opinion" className={classes.link}>
                    <Chat />
                  </NavLink>
                </Button>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false} disableGutters className={classes.footer}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.copyright}>
              Copyright © 2020 Inna Halasyova
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Footer;
