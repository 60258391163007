import MediumImage1 from '../../assets/images/production-1.jpg';
import MediumImage2 from '../../assets/images/production-2.jpg';
import MediumImage3 from '../../assets/images/production-3.jpg';
import MediumImage4 from '../../assets/images/production-4.jpg';
import MediumImage5 from '../../assets/images/production-5.jpg';
import MediumImage6 from '../../assets/images/production-6.jpg';

const tileData = [
  {
    original: MediumImage1,
    thumbnail: MediumImage1,
    description: 'Addiction',
  },
  {
    original: MediumImage2,
    thumbnail: MediumImage2,
    description: 'White, sweet and deadly',
  },
  {
    original: MediumImage3,
    thumbnail: MediumImage3,
    description: 'Quit now',
  },
  {
    original: MediumImage4,
    thumbnail: MediumImage4,
    description: 'Sugar kills',
  },
  {
    original: MediumImage5,
    thumbnail: MediumImage5,
    description: 'Diabetes type 2',
  },
  {
    original: MediumImage6,
    thumbnail: MediumImage6,
    description: 'Poison',
  },
];

export default tileData;
