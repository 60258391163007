import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './styles/theme';
import { CssBaseline } from '@material-ui/core';

import Layout from './layouts/Default';
import Home from './pages/Home';
import Research from './pages/Research';
import Opinion from './pages/Opinion';
import DataVisualization from './pages/DataVisualization';
import Production from './pages/Production';
import NotFound from './pages/NotFound';

const App = props => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout>
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/research' element={<Research />}/>
          <Route path='/opinion' element={<Opinion />}/>
          <Route path='/data-visualization' element={<DataVisualization />}/>
          <Route path='/production' element={<Production />}/>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
};

export default App;
