import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Grid, Fade } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    color: 'white',
  },
  university: {
    color: theme.palette.custom.yellow,
    backgroundColor: theme.palette.custom.black,
    padding: '8px',
    textTransform: 'uppercase',
  },
  universityText: {
    fontSize: theme.typography.pxToRem('10'),
  },
  career: {
    backgroundColor: theme.palette.custom.grey,
    color: theme.palette.custom.white,
    padding: '8px',
  },
  careerText: {
    fontSize: theme.typography.pxToRem('10'),
  },
}));

const Alert = props => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth={false} disableGutters className={classes.container}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Fade in={true} timeout={3000}>
            <Grid item xs={12} sm={8} className={classes.university} {...props}>
              <Typography
                variant="h6"
                align="center"
                className={classes.universityText}
              >
                University of Greenwich
              </Typography>
            </Grid>
          </Fade>
          <Fade in={true} timeout={4000}>
            <Grid item xs={12} sm={4} className={classes.career}>
              <Typography
                variant="h6"
                align="center"
                className={classes.careerText}
              >
                Graphic Design Practices
              </Typography>
            </Grid>
          </Fade>
        </Grid>
      </Container>
    </>
  );
};

export default Alert;
