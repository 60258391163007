import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Grid,
  Box,
  Tabs,
  Tab,
  Fade,
} from '@material-ui/core';

import OpinionImage from '../assets/images/opinion.jpg';
import data from './data/opinion';

import Header from '../components/Header';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = index => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const useStyles = makeStyles(theme => ({
  container: {
    ...theme.container,
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  grid: {
    ...theme.grid,
  },
  headerImage: {
    margin: '36px 0 16px',
  },
  tabContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 380,
    padding: '16px',
    marginBottom: '56px',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'visible',
  },
  tabBody: {
    padding: '16px',
  },
  greyBox: {
    ...theme.greyBox,
  },
  greyBoxContent: {
    ...theme.greyBoxContent,
  },
}));

const Opinion = props => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [pdfWidth, setPdfWidth] = useState(window.innerWidth - 44);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth <= 1870) {
        setPdfWidth(window.innerWidth - 44);
      } else {
        setPdfWidth(1871);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [pdfWidth]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fade in={true} timeout={6000} {...props}>
      <Container
        maxWidth={false}
        disableGutters
        className={classes.container}
        id="container"
        {...props}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} className={classes.grid}>
            <Header title={data.title} subTitle={data.subTitle} />
            <img
              src={OpinionImage}
              width={pdfWidth}
              alt="Fatty liver"
              className={classes.headerImage}
            />
            <Typography variant="body1" component="p">
              {data.paragraph1}
            </Typography>
            <Grid item xs={12} className={classes.greyBox}>
              <Typography variant="h6" className={classes.greyBoxContent}>
                {data.paragraph2.title}
              </Typography>
              <Typography variant="body1" className={classes.greyBoxContent}>
                {data.paragraph2.body}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.tabContainer}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab label={data.item1.title} {...a11yProps(0)} />
              <Tab label={data.item2.title} {...a11yProps(1)} />
              <Tab label={data.item3.title} {...a11yProps(2)} />
              <Tab label={data.item4.title} {...a11yProps(3)} />
              <Tab label={data.item5.title} {...a11yProps(4)} />
              <Tab label={data.item6.title} {...a11yProps(5)} />
              <Tab label={data.item7.title} {...a11yProps(6)} />
            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabBody}>
              {data.item1.body}
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabBody}>
              {data.item2.body}
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabBody}>
              {data.item3.body}
            </TabPanel>
            <TabPanel value={value} index={3} className={classes.tabBody}>
              {data.item4.body}
            </TabPanel>
            <TabPanel value={value} index={4} className={classes.tabBody}>
              {data.item5.body}
            </TabPanel>
            <TabPanel value={value} index={5} className={classes.tabBody}>
              {data.item6.body}
            </TabPanel>
            <TabPanel value={value} index={6} className={classes.tabBody}>
              {data.item7.body}
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </Fade>
  );
};

export default Opinion;
