const dataVisualization = {
  title: 'Data visualization',
  subTitle: 'Methodology of data collection',
  chart1: {
    title: 'Sugar added to drinks',
    subTitle: 'University of Greenwitch coffee shop',
    element1: `
    Adding sugar to drinks by habbit
`,
    element2: `
    Adding sugar to drinks by foreknowdledge that our body does need sugar
`,
    element3: `
    Adding sugar to drinks by people with knowledge they should not do it
`,
    element4: `
    Adding sugar to drinks by people who avoiding sugar in their diet
`,
    element5: `
    Adding sugar to drinks by people who following healthy life style
`,
  },
  chart2: {
    title: 'Daily sugar intake added to drinks',
    subTitle: 'University of Greenwitch coffee shop',
  },
  chart3: {
    title: 'Quantity of sugar added to drinks',
    subTitle: 'University of Greenwitch coffee shop',
  },
  paragraph1: `
  Observation, survey.
`,
  paragraph2: {
    title: 'Period of data collection',
    body: '10am to 6pm (Monday)'
  },
  paragraph3: `
  In my study I have observed customers who added sugar to their drinks at the Coffee shop.
`,
  paragraph4: `
  I did a survey with customers who added sugar to drinks with one question: What eating lifestyle do you most relate to:
`,
};

export default dataVisualization;
