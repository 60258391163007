import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Grid,
  Fade,
  Link
} from '@material-ui/core';
import { Link as UILink } from '@material-ui/icons';
import tileData from './data/tileData';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import data from './data/production';

import Header from '../components/Header';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    marginTop: '16px',
    marginLeft: '16px',
    marginRight: '16px',
  },
  container: {
    ...theme.container,
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  grid: {
    ...theme.grid,
  },
  carousel: {
    width: '100%',
  },
  icon: {
    marginRight: '8px',
    color: theme.palette.custom.cream,
  },
  greyBox: {
    ...theme.greyBox,
  },
  greyBoxContent: {
    ...theme.greyBoxContent,
  },
  whiteBox: {
    ...theme.whiteBox,
    marginTop: '36px',
  },
  whiteBoxContent: {
    ...theme.whiteBoxContent,
  },
  linkBox: {
    ...theme.linkBox,
  },
  linkBoxInline: {
    ...theme.linkBox,
    display: 'inline',
  },
  linkItem: {
    ...theme.linkItem,
  },
  linkIcon: {
    ...theme.linkIcon,
  },
}));

const Production = props => {
  const classes = useStyles();

  return (
    <Fade in={true} timeout={6000}>
      <Container
        maxWidth={false}
        disableGutters
        className={classes.container}
        id="container"
        {...props}
      >
        <Grid container direction='row' justify='center' alignItems='center'>
          <Grid item xs={12} className={classes.grid}>
            <Header title={data.title} subTitle={data.subTitle} />

            <Grid item xs={12} className={classes.greyBox}>
              <Typography variant="h6" className={classes.greyBoxContent}>
                {data.paragraph1.title}
              </Typography>
              <Typography variant="body1" className={classes.greyBoxContent}>
                {data.paragraph1.body}
              </Typography>
            </Grid>

            <Typography variant='body1' component="p">
              {data.paragraph2}
            </Typography>

            <Typography variant='body1' component="span">
              {data.paragraph3.title}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              className={classes.linkBox}
            >
              <Link
                href={data.paragraph3.reference}
                target="_blank"
                rel="noopener"
                className={classes.linkItem}
              >
                <UILink className={classes.linkIcon} />
                Reference
              </Link>
            </Typography>

            <Grid item xs={12} className={classes.whiteBox}>
              <Typography variant="body1" className={classes.whiteBoxContent}>
                {data.paragraph4}
              </Typography>
            </Grid>
          </Grid>

          <div className={classes.root}>
            <ImageGallery
              className={classes.carousel}
              items={tileData}
              thumbnailPosition='top'
              showPlayButton={false}
            />
          </div>
        </Grid>
      </Container>
    </Fade>
  );
};

export default Production;
