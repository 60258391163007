const opinion = {
  title: 'Opinion',
  subTitle: "We don't need added sugar in our food",
  paragraph1: `
    In my opinion, sugar is poison.
`,
  paragraph2: {
    title: 'It is an addiction and manufactures seem to be profiting from it',
    body: `
     The industry doesn’t care about population health; the companies producing sugary goods mostly care about their money.
    `,
  },
  item1: {
    title: 'One',
    body: `
      From the outcome of my research, numbers clearly show that people likely believed that some amount of sugar is essential for our lifestyle. People that grouped themselves in bad habits (total of 169 teaspoons), medium style (152 teaspoons) and soft style (146 teaspoons) have similar data. Between Group of people with Healthy style (81 teaspoons) and Avoiding sugar (39 teaspoons) the rate of added sugar is significantly lower.
    `,
  },
  item2: {
    title: 'Two',
    body: `
      We don’t need to consume any added sugar in our food except naturally occurred sugars in fruits and milk. The food industry should give a choice to the population where we can decide between added sugar and no sugar.
    `,
  },
  item3: {
    title: 'Three',
    body: `
      Walking through the supermarket shelf, you will hardly find any of no added sugar products. Despite this, in the past few years, I have been able to find such products, including chocolate, ice cream and biscuits.
    `,
  },
  item4: {
    title: 'Four',
    body: `
      "Healthy Choice" label doesn’t guarantee that a particular product doesn’t contain sugar. Even though they advertise the opposite – they have more sugar than an ordinary labelled product.
    `,
  },
  item5: {
    title: 'Five',
    body: `
      Promotions are more common for high sugar products (particularly drinks, biscuits and cakes) and that discounted prices remarkably increasing sales of high sugar products.
    `,
  },
  item6: {
    title: 'Six',
    body: `
      In the UK, it is almost impossible to find products that contain natural sweeteners, for example, Stevia. For comparison in other European countries, we can buy sugar-free products, even cakes and Ice cream. These products displayed on the separate shelf and are visibly labelled.
    `,
  },
  item7: {
    title: 'Seven',
    body: `
      The population should not be afraid of artificial sweeteners with zero calories. Experts prove that sweeteners don’t cause harm when consumed in an appropriate amount, and cause less damage than commercial sugar.
    `,
  },
};

export default opinion;
