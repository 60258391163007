const production = {
  title: 'Production',
  subTitle: 'Sugar packaging',
  paragraph1: {
    title: 'Is it time to treat sugar like smoking?',
    body: `
      Sugar is worse than tobacco due to tobacco is not hidden in processed food.
    `,
  },
  paragraph2: `
    Over the past decade, smoking has become marginalised and stigmatised.
`,
  paragraph3: {
    title: 'From the smoking ban in 2007 to the introduction of plain packaging a decade later, everything has been done to discourage people from taking up the habit. And the same approach with sugar labelling is needed, to make people aware of the danger of sugar consumption.',
    reference: "https://www.bbc.co.uk/news/health-48499195",
  },
  paragraph4: `
    For my final production, I have created a sugar packaging with similar warn signs as on cigarette packaging. By creating these images, I strongly believe that this type of packaging makes people think and maybe choose an alternative healthy sweetened product.
`,
};

export default production;
