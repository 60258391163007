import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fade,
  Button,
  ButtonGroup,
  Paper,
  Link,
} from '@material-ui/core';
import { ExpandMore, Link as UILink } from '@material-ui/icons';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import pdf from '../assets/pdf/sketchbook.pdf';
import FattyLiverImage from '../assets/images/fatty-liver.png';
import BrainCellsImage from '../assets/images/brain-cells.jpg';

import data from './data/research';

import Header from '../components/Header';

const useStyles = makeStyles(theme => ({
  container: {
    ...theme.container,
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  grid: {
    ...theme.grid,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordionElement: {
    boxShadow: 'none',
  },
  accordionTitle: {
    marginBottom: '0',
  },
  linkBox: {
    ...theme.linkBox,
  },
  linkBoxInline: {
    ...theme.linkBox,
    display: 'inline',
  },
  linkItem: {
    ...theme.linkItem,
  },
  linkIcon: {
    ...theme.linkIcon,
  },
  panelDetails: {
    display: 'block',
  },
  pdfContainer: {
    padding: '16px 16px 0 16px',
    textAlign: 'center',
  },
  pdfPages: {
    padding: '16px',
    color: theme.palette.custom.black,
    backgroundColor: theme.palette.custom.yellow,
    margin: '0 auto',
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  pdfButtons: {
    borderRadius: '0',
  },
  greyBox: {
    ...theme.greyBox,
  },
  greyBoxContent: {
    ...theme.greyBoxContent,
  },
  yellowBox: {
    ...theme.yellowBox,
  },
  yellowBoxContent: {
    ...theme.yellowBoxContent,
  },
  headerImage: {
    margin: '36px 0 16px',
  },
}));

const Research = props => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfWidth, setPdfWidth] = useState(window.innerWidth - 44);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const OnDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth <= 1870) {
        setPdfWidth(window.innerWidth - 44);
      } else {
        setPdfWidth(1871);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [pdfWidth]);

  return (
    <Fade in={true} timeout={6000}>
      <Container
        maxWidth={false}
        disableGutters
        className={classes.container}
        id="container"
        {...props}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} className={classes.grid}>
            <Header title={data.title} subTitle={data.subTitle} />
            <Typography variant="body1" component="p">
              {data.paragraph1}
            </Typography>
            <Typography variant="body1" component="p">
              {data.paragraph2}
            </Typography>
            <Typography variant="body1" component="p">
              {data.paragraph3}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.linkBox}
            >
              <Link
                href={data.reference}
                target="_blank"
                rel="noopener"
                className={classes.linkItem}
              >
                <UILink className={classes.linkIcon} />
                Reference: Sugar reduction - Report on progress between 2.015
                and 2.018 (page 41)
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.grid}>
            <Header
              title={data.group1.title}
              subTitle={data.group1.subTitle}
              small
            />
            <Typography variant="body1" component="div">
              {data.group1.paragraph1}
              <ul>
                <li>
                  <strong>Intended use.</strong> Many sugar substitutes do not
                  withstand high temperatures, so they would make poor choices
                  for baking
                </li>
                <li>
                  <strong>Cost.</strong> Some sugar substitutes are expensive,
                  whereas others have a cost closer to that of table sugar
                </li>
                <li>
                  <strong>Availability.</strong> Some sugar substitutes are
                  easier to find in stores than others
                </li>
                <li>
                  <strong>Taste.</strong> Some sugar substitutes, such as
                  stevia, have a bitter aftertaste that many people may find
                  unpleasant. Make sure that the manufacturers have not added
                  chemicals or other sweeteners that reduce the nutritional
                  benefit
                </li>
                <li>
                  <strong>Natural versus artificial.</strong> Some people prefer
                  using natural sweeteners, such as stevia, rather than
                  artificial sugar substitutes. However, natural does not always
                  mean lower-calorie or more healthful
                </li>
                <li>
                  <strong>Add fruit instead of sweetener.</strong> Where
                  possible, add a sweet fruit to a meal instead of sugar or
                  artificial sweeteners. Options include strawberry, blueberry,
                  and mango
                </li>
              </ul>
            </Typography>

            <ExpansionPanel
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
              className={classes.accordionElement}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.accordionTitle}>
                  {data.expansionPanel1.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.panelDetails}>
                <Typography>{data.expansionPanel1.body}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
              className={classes.accordionElement}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.accordionTitle}>
                  {data.expansionPanel2.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.panelDetails}>
                <Typography>{data.expansionPanel2.body}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
              className={classes.accordionElement}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.accordionTitle}>
                  {data.expansionPanel3.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.panelDetails}>
                <Typography>{data.expansionPanel3.body}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
              className={classes.accordionElement}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className={classes.accordionTitle}>
                  {data.expansionPanel4.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.panelDetails}>
                <Typography>{data.expansionPanel4.body}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
              className={classes.accordionElement}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography className={classes.accordionTitle}>
                  {data.expansionPanel5.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.panelDetails}>
                <Typography>{data.expansionPanel5.body}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel6'}
              onChange={handleChange('panel6')}
              className={classes.accordionElement}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
                <Typography className={classes.accordionTitle}>
                  {data.expansionPanel6.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.panelDetails}>
                <Typography>{data.expansionPanel6.body}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel7'}
              onChange={handleChange('panel7')}
              className={classes.accordionElement}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <Typography className={classes.accordionTitle}>
                  {data.expansionPanel7.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.panelDetails}>
                <Typography>{data.expansionPanel7.body}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <Typography
              variant="body1"
              component="div"
              className={classes.linkBox}
            >
              <Link
                href={data.group1.reference}
                target="_blank"
                rel="noopener"
                className={classes.linkItem}
              >
                <UILink className={classes.linkIcon} />
                {data.group1.reference}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.grid}>
            <Header
              title={data.group2.title}
              subTitle={data.group2.subTitle}
              small
            />
            <img
              src={FattyLiverImage}
              width={pdfWidth}
              alt="Fatty liver"
              className={classes.headerImage}
            />
            <Typography variant="body1" component="div">
              {data.group2.paragraph1}
              <Typography
                variant="body1"
                component="span"
                className={classes.linkBox}
              >
                <Link
                  href={data.group2.paragraph1Reference}
                  target="_blank"
                  rel="noopener"
                  className={classes.linkItem}
                >
                  <UILink className={classes.linkIcon} />
                  Reference
                </Link>
              </Typography>
            </Typography>

            <Typography variant="body1" component="div">
              {data.group2.paragraph2}
            </Typography>
            <Typography variant="body1" component="div">
              {data.group2.paragraph3}
              <Typography
                variant="body1"
                component="span"
                className={classes.linkBox}
              >
                <Link
                  href={data.group2.paragraph3Reference}
                  target="_blank"
                  rel="noopener"
                  className={classes.linkItem}
                >
                  <UILink className={classes.linkIcon} />
                  Reference
                </Link>
              </Typography>
            </Typography>
            <Typography variant="body1" component="div">
              {data.group2.paragraph4}
            </Typography>
            <Typography variant="body1" component="div">
              {data.group2.paragraph5}
            </Typography>
            <Typography variant="body1" component="div">
              {data.group2.paragraph6}
              <Typography
                variant="body1"
                component="span"
                className={classes.linkBox}
              >
                <Link
                  href={data.group2.paragraph6Reference}
                  target="_blank"
                  rel="noopener"
                  className={classes.linkItem}
                >
                  <UILink className={classes.linkIcon} />
                  Reference
                </Link>
              </Typography>
            </Typography>

            <Grid item xs={12} className={classes.greyBox}>
              <Typography variant="h6" className={classes.greyBoxContent}>
                {data.group2.paragraph7.header}
              </Typography>
              <Typography variant="body1" className={classes.greyBoxContent}>
                {data.group2.paragraph7.body}
              </Typography>
            </Grid>

            <Typography
              variant="body1"
              component="div"
              className={classes.linkBox}
            >
              <Link
                href={data.group2.paragraph7.reference}
                target="_blank"
                rel="noopener"
                className={classes.linkItem}
              >
                <UILink className={classes.linkIcon} />
                {data.group2.paragraph7.reference}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.grid}>
            <Header
              title={data.group3.title}
              subTitle={data.group3.subTitle}
              small
            />
            <img
              src={BrainCellsImage}
              width={pdfWidth}
              alt="Brain Cells"
              className={classes.headerImage}
            />
            <Typography variant="body1" component="div">
              {data.group3.paragraph1}
            </Typography>
            <Typography variant="body1" component="div">
              {data.group3.paragraph2}
            </Typography>

            <Grid item xs={12} className={classes.yellowBox}>
              <Typography variant="h6" className={classes.yellowBoxContent}>
                {data.group3.paragraph3}
              </Typography>
              <Typography variant="body1" className={classes.yellowBoxContent}>
                {data.group3.paragraph4}
              </Typography>
            </Grid>

            <Typography
              variant="body1"
              component="div"
              className={classes.linkBox}
            >
              <Link
                href={data.group3.reference}
                target="_blank"
                rel="noopener"
                className={classes.linkItem}
              >
                <UILink className={classes.linkIcon} />
                {data.group3.reference}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.grid}>
            <Header
              title={data.group4.title}
              subTitle={data.group4.subTitle}
              small
            />
            <ul>
              <li>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.linkBox}
                >
                  <Link
                    href={data.group4.link1.reference}
                    target="_blank"
                    rel="noopener"
                    className={classes.linkItem}
                  >
                    <UILink className={classes.linkIcon} />
                    {data.group4.link1.text}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.linkBox}
                >
                  <Link
                    href={data.group4.link2.reference}
                    target="_blank"
                    rel="noopener"
                    className={classes.linkItem}
                  >
                    <UILink className={classes.linkIcon} />
                    {data.group4.link2.text}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.linkBox}
                >
                  <Link
                    href={data.group4.link3.reference}
                    target="_blank"
                    rel="noopener"
                    className={classes.linkItem}
                  >
                    <UILink className={classes.linkIcon} />
                    {data.group4.link3.text}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.linkBox}
                >
                  <Link
                    href={data.group4.link4.reference}
                    target="_blank"
                    rel="noopener"
                    className={classes.linkItem}
                  >
                    <UILink className={classes.linkIcon} />
                    {data.group4.link4.text}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.linkBox}
                >
                  <Link
                    href={data.group4.link5.reference}
                    target="_blank"
                    rel="noopener"
                    className={classes.linkItem}
                  >
                    <UILink className={classes.linkIcon} />
                    {data.group4.link5.text}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.linkBox}
                >
                  <Link
                    href={data.group4.link6.reference}
                    target="_blank"
                    rel="noopener"
                    className={classes.linkItem}
                  >
                    <UILink className={classes.linkIcon} />
                    {data.group4.link6.text}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.linkBox}
                >
                  <Link
                    href={data.group4.link7.reference}
                    target="_blank"
                    rel="noopener"
                    className={classes.linkItem}
                  >
                    <UILink className={classes.linkIcon} />
                    {data.group4.link7.text}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.linkBox}
                >
                  <Link
                    href={data.group4.link8.reference}
                    target="_blank"
                    rel="noopener"
                    className={classes.linkItem}
                  >
                    <UILink className={classes.linkIcon} />
                    {data.group4.link8.text}
                  </Link>
                </Typography>
              </li>
            </ul>
          </Grid>

          <Grid item xs={12} className={classes.grid}>
            <Header title={data.pdf.title} subTitle={data.pdf.subTitle} small />
            <Typography variant="body1" component="p">
              {data.pdf.paragraph1}
            </Typography>
            <Typography variant="body1" component="p">
              {data.pdf.paragraph2}
            </Typography>

            <Grid item xs={12} className={classes.pdfContainer}>
              <ButtonGroup>
                <Button
                  disableElevation
                  variant="contained"
                  disabled={pageNumber === 1}
                  onClick={goToPrevPage}
                  className={classes.pdfButtons}
                >
                  Prev
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  disabled={pageNumber === numPages}
                  onClick={goToNextPage}
                  className={classes.pdfButtons}
                >
                  Next
                </Button>
              </ButtonGroup>
              <Document file={pdf} onLoadSuccess={OnDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} width={pdfWidth} />
              </Document>
              <Paper
                elevation={0}
                component="div"
                square={true}
                className={classes.pdfPages}
              >
                Page <strong>{pageNumber}</strong> of{' '}
                <strong>{numPages}</strong>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fade>
  );
};

export default Research;
