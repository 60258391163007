import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const colors = {
  black: '#000000',
  grey: '#5d6d7c',
  white: '#f2efe2',
  yellow: '#fdd000',
  cream: '#e3a765',
};

const link = {
  box: {
    margin: '24px 0 0',
  },
  item: {
    textDecoration: 'none',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'inline-block',
    color: colors.black,
    padding: '0 8px',
    transition: 'color ease 0.3s',
    fontWeight: 700,

    '&:hover, &:focus, &:active, &:visited': {
      outline: 'none',
      color: colors.black,
      textDecoration: 'none',

      '&:after': {
        height: '100%',
      },
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: '-1',
      width: '100%',
      height: '5%',
      left: 0,
      bottom: 0,
      backgroundColor: colors.yellow,
      transition: 'all ease 0.3s',
    },
  },
  icon: {
    color: colors.grey,
    float: 'left',
    paddingRight: '10px',
  },
};

const box = {
  container: {
    padding: '24px',
    margin: '16px 0',
  },
  content: {
    display: 'block',
    marginBottom: '16px',
  },
};

const theme = createMuiTheme({
  palette: {
    custom: {
      black: colors.black,
      grey: colors.grey,
      white: colors.white,
      yellow: colors.yellow,
      cream: colors.cream,
    },
  },
  typography: {
    htmlFontSize: 20,
    fontFamily:
      "'Work Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    fontSize: 20,
    fontWeight: 300,
    fontWeightBold: 900,
    h1: {
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 900,
      fontSize: '6rem',
      lineHeight: 1.167,
    },
    h2: {
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 900,
      fontSize: '5rem',
      lineHeight: 1.167,
    },
    h3: {
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 900,
      fontSize: '3rem',
      lineHeight: 1.167,
    },
    h4: {
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 900,
      fontSize: '2.25rem',
      lineHeight: 1.235,
    },
    h5: {
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 300,
      fontSize: '1.5rem',
      lineHeight: 1.334,
    },
    h6: {
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: 1.6,
    },
    subtitle1: {
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 300,
      fontSize: '1rem',
      lineHeight: 1.7,
    },
    subtitle2: {
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.72,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: 1.7,
      marginBottom: '1.7rem',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 300,
      lineHeight: 1.72,
      marginBottom: '1.72rem',
    },
    button: {
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.7,
    },
    caption: {
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.72,
      marginBottom: '1.72rem',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 300,
      lineHeight: 1.66,
      marginBottom: '1.66rem',
    },
  },
  overrides: {},
  container: {
    marginTop: '64px',
  },
  grid: {
    padding: '16px',
    marginBottom: '0',
  },
  divider: {
    marginBottom: '1.3rem',
  },
  blockquote: {
    borderLeft: '9px solid #5d6d7c',
    padding: '16px',
    marginRight: '16px',
    marginLeft: '16px',
    fontStyle: 'italic',
  },
  header: {
    marginTop: '16px',
    position: 'relative',
  },
  headerIcon: {
    float: 'left',
    marginRight: '12px',
    marginBottom: '0',
    paddingTop: '4px',
    fontWeight: 900,
    color: colors.yellow,
  },
  headerIconSmall: {
    float: 'left',
    marginRight: '12px',
    marginBottom: '0',
    marginTop: '-5px',
    fontWeight: 900,
    color: colors.yellow,
  },
  subHeader: {
    paddingBottom: '16px',
    clear: 'both',
    color: colors.grey,
  },
  linkBox: {
    ...link.box,
  },
  linkItem: {
    ...link.item,
  },
  linkIcon: {
    ...link.icon,
  },
  greyBox: {
    ...box.container,
    backgroundColor: colors.grey,
    color: colors.white,
  },
  greyBoxContent: {
    ...box.content,
  },
  yellowBox: {
    ...box.container,
    backgroundColor: colors.yellow,
    color: colors.black,
  },
  yellowBoxContent: {
    ...box.content,
  },
  whiteBox: {
    ...box.container,
    backgroundColor: colors.white,
    color: colors.black,
  },
  whiteBoxContent: {
    ...box.content,
  },
  blackBox: {
    ...box.container,
    backgroundColor: colors.black,
    color: colors.yellow,
  },
  blackBoxContent: {
    ...box.content,
  },
  creamBox: {
    ...box.container,
    backgroundColor: colors.cream,
    color: colors.black,
  },
  creamBoxContent: {
    ...box.content,
  },
});

export default responsiveFontSizes(theme);
