import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Grid, Fade } from '@material-ui/core';
import { EditLocation } from '@material-ui/icons';

import data from './data/not-found';

import Header from '../components/Header';

const useStyles = makeStyles(theme => ({
  container: {
    ...theme.container,
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  icon: {
    marginRight: '8px',
    color: theme.palette.custom.cream,
    display: 'block',
    fontSize: theme.typography.pxToRem(128),
    marginTop: '64px',
    textAlign: 'center',
    margin: '0 auto',
    width: '100%',
  },
  content: {
    textAlign: 'center',
  },
}));

const NotFound = props => {
  const classes = useStyles();

  return (
    <Fade in={true} timeout={6000}>
      <Container
        maxWidth={false}
        disableGutters
        className={classes.container}
        id="container"
        {...props}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} id="not-found" className={classes.content}>
            <EditLocation className={classes.icon} />
            <Header title={data.title} subTitle={data.subTitle} noIcon/>
            <Typography variant="body1">
              {data.body}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Fade>
  );
};

export default NotFound;
