import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { useScrollTrigger, Zoom, Fab } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab: {
    background: theme.palette.custom.yellow,
    color: theme.palette.custom.black,
  },
}));

const BackToTop = props => {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#top-navbar',
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.container}
      >
        {children}
      </div>
    </Zoom>
  );
};

BackToTop.propTypes = {
  children: PropTypes.element.isRequired,
};

const ScrollTop = props => {
  const classes = useStyles();

  return (
    <BackToTop {...props}>
      <Fab className={classes.fab} size="small" aria-label="scroll back to top">
        <KeyboardArrowUp />
      </Fab>
    </BackToTop>
  );
};

export default ScrollTop;
