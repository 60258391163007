import React from 'react';

import { Typography, Container, Grid, Fade, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ResponsiveAreaBump } from '@nivo/bump';
import { ResponsiveChord } from '@nivo/chord';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';

import data from './data/data-visualization';
import chartData1 from './data/visualization/chart1';
import chartData2 from './data/visualization/chart2';
import chartData3 from './data/visualization/chart3';

import Header from '../components/Header';

const useStyles = makeStyles(theme => ({
  container: {
    ...theme.container,
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  grid: {
    ...theme.grid,
  },
  divider: {
    ...theme.divider,
  },
  chart: {
    padding: '16px',
    height: '600px',
  },
  chart1: {
    padding: '16px',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  greyBox: {
    ...theme.greyBox,
  },
  greyBoxContent: {
    ...theme.greyBoxContent,
  },
  chartHeaderLegendIcon: {
    marginLeft: '-4px',
    marginBottom: '8px',
  },
  chartItemLegend: {
    marginBottom: 0,
  },
  chart1Red: {
    width: '16px',
    display: 'inline-block',
    paddingBottom: '16px',
    marginRight: '8px',
    position: 'relative',
    backgroundColor: '#f47c67',
  },
  chart1Yellow: {
    width: '16px',
    display: 'inline-block',
    paddingBottom: '16px',
    marginRight: '8px',
    position: 'relative',
    backgroundColor: '#ecde60',
  },
  chart1Orange: {
    width: '16px',
    display: 'inline-block',
    paddingBottom: '16px',
    marginRight: '8px',
    position: 'relative',
    backgroundColor: '#e8b767',
  },
  chart1Green: {
    width: '16px',
    display: 'inline-block',
    paddingBottom: '16px',
    marginRight: '8px',
    position: 'relative',
    backgroundColor: '#5ec8b8',
  },
  chart1Cream: {
    width: '16px',
    display: 'inline-block',
    paddingBottom: '16px',
    marginRight: '8px',
    position: 'relative',
    backgroundColor: '#e3be9c',
  },
}));

const DataVisualization = props => {
  const classes = useStyles();

  return (
    <Fade in={true} timeout={6000}>
      <Container
        maxWidth={false}
        disableGutters
        className={classes.container}
        id="container"
        {...props}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} className={classes.grid}>
            <Header title={data.title} subTitle={data.subTitle} />
            <Typography variant="body1" component="p">
              {data.paragraph1}
            </Typography>
            <Typography variant="body1" component="p">
              {data.paragraph3}
            </Typography>
            <Typography variant="body1" component="p">
              {data.paragraph4}
            </Typography>
            <Typography variant="body1" component="div">
              <ul>
                <li>
                  <strong>Bad habits.</strong> Who doesn’t control their diet at
                  all
                </li>
                <li>
                  <strong>Medium style.</strong> Who control their diet, but
                  they are not aware of their sugar consumption
                </li>
                <li>
                  <strong>Soft style.</strong> Who are aware of sugar reduction
                  in their diet, but they believe that we still need some sugar
                  as recommended daily intake
                </li>
                <li>
                  <strong>Healthy style.</strong> Who follow healthy style in
                  their diet
                </li>
                <li>
                  <strong>Avoiding sugar.</strong> Who avoiding sweet
                  confectionery and ready meals
                </li>
              </ul>
            </Typography>

            <Grid item xs={12} className={classes.greyBox}>
              <Typography variant="h6" className={classes.greyBoxContent}>
                {data.paragraph2.title}
              </Typography>
              <Typography variant="body1" className={classes.greyBoxContent}>
                {data.paragraph2.body}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} className={classes.grid}>
            <Header
              title={data.chart1.title}
              subTitle={data.chart1.subTitle}
              small
            />
            <Typography variant="body1" component="div">
              <Typography
                variant="body1"
                component="p"
                className={classes.chartItemLegend}
              >
                <span className={classes.chart1Red}></span>
                {data.chart1.element1}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.chartItemLegend}
              >
                <span className={classes.chart1Yellow}></span>
                {data.chart1.element2}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.chartItemLegend}
              >
                <span className={classes.chart1Orange}></span>
                {data.chart1.element3}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.chartItemLegend}
              >
                <span className={classes.chart1Green}></span>
                {data.chart1.element4}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.chartItemLegend}
              >
                <span className={classes.chart1Cream}></span>
                {data.chart1.element5}
              </Typography>
            </Typography>
            <Divider className={classes.divider} />
            <Typography
              variant="body1"
              component="div"
              className={classes.chart}
            >
              <ResponsiveAreaBump
                data={chartData1}
                margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
                spacing={8}
                colors={{ scheme: 'nivo' }}
                blendMode="multiply"
                startLabel="id"
                axisTop={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -36,
                }}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
              />
            </Typography>
            <Header
              title={data.chart2.title}
              subTitle={data.chart2.subTitle}
              small
            />
            <Typography
              variant="body1"
              component="div"
              className={classes.chart}
            >
              <ResponsiveChord
                matrix={chartData2}
                keys={[
                  'Healthy style',
                  'Bad habits',
                  'Soft style',
                  'Medium style',
                  'Avoiding sugar',
                ]}
                margin={{ top: 60, right: 60, bottom: 90, left: 60 }}
                valueFormat=".2f"
                padAngle={0.02}
                innerRadiusRatio={0.96}
                innerRadiusOffset={0.02}
                arcOpacity={1}
                arcBorderWidth={1}
                arcBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
                ribbonOpacity={0.5}
                ribbonBorderWidth={1}
                ribbonBorderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.4]],
                }}
                enableLabel={true}
                label="id"
                labelOffset={12}
                labelRotation={-90}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1]] }}
                colors={{ scheme: 'nivo' }}
                isInteractive={true}
                arcHoverOpacity={1}
                arcHoverOthersOpacity={0.25}
                ribbonHoverOpacity={0.75}
                ribbonHoverOthersOpacity={0.25}
                animate={true}
                motionStiffness={90}
                motionDamping={7}
                legends={[
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 70,
                    itemWidth: 80,
                    itemHeight: 14,
                    itemsSpacing: 0,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemTextColor: '#000',
                        },
                      },
                    ],
                  },
                ]}
              />
            </Typography>
            <Header
              title={data.chart3.title}
              subTitle={data.chart3.subTitle}
              small
            />
            <Typography
              variant="body1"
              component="div"
              className={classes.chart}
            >
              <ResponsiveScatterPlot
                data={chartData3}
                margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
                xScale={{ type: 'linear', min: 10, max: 'auto' }}
                xFormat={function(e) {
                  return e + ':00';
                }}
                yScale={{ type: 'linear', min: 0, max: 'auto' }}
                yFormat={function(e) {
                  return e + ' tea spoons';
                }}
                blendMode="multiply"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'hours',
                  legendPosition: 'middle',
                  legendOffset: 46,
                  format: d => `${d}:00`,
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'tea spoons',
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                legends={[
                  {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 130,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 12,
                    itemsSpacing: 5,
                    itemDirection: 'left-to-right',
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Fade>
  );
};

export default DataVisualization;
